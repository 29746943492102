import { Link, StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import PropTypes from 'prop-types'
import React from 'react'
import { IconContext } from "react-icons";


import DrawerToggleButton from '../sidedrawer/drawerToggleButton'
import './navbar.css'

const Navbar = (props) => (
  <IconContext.Provider value={{ color: "var(--primary-color)", style: { paddingBottom: '2px'}}}>
  <header className={props.navClasses}>
    <nav className="navbar_navigation">
      <div className="navbar_toggle-button">
        <DrawerToggleButton click={props.drawerClickHandler} />
      </div>
      <div className="logo-container">
        <Logo />
        <div className="navbar_logo">
          <h1><Link to="/">{props.siteTitle}</Link></h1>
          <h4>Serving West Central New Hampshire</h4>
        </div>
      </div>
      <div className="spacer" />
      <div className="navbar_navigation-items">
        <ul>
          {/* <li>
            <Link onClick={props.menuLeaveHandler} onMouseEnter={props.menuEnterHandler} className="drop-parent" to="/practice-areas/">PRACTICE AREAS <FaSortDown /></Link>
            <ul  onMouseLeave={props.menuLeaveHandler} className={props.classes}>
              <li><Link to="/real-estate-title-law/" onClick={props.menuLeaveHandler}>Real Estate Titles & Law</Link></li>
              <li><Link to="/probate-administration/" onClick={props.menuLeaveHandler}>Probate Administration</Link></li>
              <li><Link to="/estate-planning/" onClick={props.menuLeaveHandler}>Estate Planning & Wills</Link></li>
              <li><Link to="/general-practice-law/" onClick={props.menuLeaveHandler}>General Practice</Link></li>
              <li><Link to="/personal-injury/" onClick={props.menuLeaveHandler}>Personal Injury</Link></li>
            </ul>
          </li> */}
          <li><Link to="/title-insurance/">TITLE INSURANCE</Link></li>
          <li><Link to="/contact/">CONTACT</Link></li>
          <li><Link to="/about/">ABOUT</Link></li>


        </ul>
      </div>
      {/* <div className="call-button">
        <a href="tel:603-978-5354">CALL US</a>
      </div> */}
    </nav>
  </header>
  </IconContext.Provider>
);

const Logo = props => (
  <StaticQuery
    query={query}
    render={data => (
      <div className="logo">
        <Img alt="Feleen Law Logo" fluid={data.file.childImageSharp.fluid}/>
      </div>
    )}
  />
);

const query = graphql`
  query {
    file(relativePath: { eq: "pleasant-title-logo.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 120, quality: 90) {
          # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
            ...GatsbyImageSharpFluid
        }
      }
    }
  }
`


Navbar.propTypes = {
  siteTitle: PropTypes.string,
}

Navbar.defaultProps = {
  siteTitle: ``,
}

export default Navbar
