import { Link } from 'gatsby'
import React from 'react'

import './sideDrawer.css'

const sideDrawer = props => {
  let drawerClasses = 'side-drawer';

  if (props.show) {
    drawerClasses = 'side-drawer open'
  }

  return (
    <nav className={drawerClasses}>
    <ul className="mainmen">
      <li><Link to="/" onClick={props.drawerClickHandler}>HOME</Link></li>
      <li><Link to="/title-insurance/" onClick={props.drawerClickHandler}>TITLE INSURANCE</Link></li>
      <li><Link to="/contact/" onClick={props.drawerClickHandler}>CONTACT</Link></li>
      <li><Link to="/about/" onClick={props.drawerClickHandler}>ABOUT</Link></li>
    </ul>
    <div className="call-button">
          <a href="tel:603-504-6507">CALL US NOW</a>
      </div>
  </nav>
);

};

export default sideDrawer;
